const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://chango.dvgeo.app' : 'http://192.168.100.106:3041',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://chango.dvgeo.app' : 'http://192.168.100.106:3041',
    api: 'api/',
    apiSocket: 'chango::1.2',
    nameDir: 'chango',
    package: 'com.changobolivia.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCnxH39hmks5qrw0Bxp_VTmPezkFYvxJFE',
    appName: 'Chango',
    provider: '',
    colorPrimary: '#CA362E',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.changobolivia.passenger',
    playStoreDriverId: 'com.changobolivia.driver',
    appStorePassengerId: '6503668491',
    appStoreDriverId: '6503668549',
    email: "edsonmandelaosinaga@gmail.com",
};
export default config;
